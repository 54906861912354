@import '../../styles/variables.scss';
// :: ---

.contentsection {
  margin-top: $gap;
  margin-bottom: $gap;
}

.contentsectiontitle {
  border-bottom: 1px solid rgba(black, 0.26);
  padding-bottom: $gap / 4;
  font-size: 1rem !important;
  color: $brown !important;
}

.scaleAspectRatio {
  object-fit: contain;
  display: inline-block;
}

.menu {
  padding: 20px;
  position: fixed;
  margin-top: 64px;
  width: 25%;
}

.menu a {
  color: #7A7A7A;
  font-weight: normal;
  font-size: 1.1vw;
}

.menu a:hover {
  color: #DE750E;
}

.menu div {
  color: black;
  font-weight: 500;
}
