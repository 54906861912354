@import '../../styles/variables.scss';

// :: ---

.help {
  position: fixed;
  bottom: 0px;
  right:10px;
}

// h1 {
//   font-size: 2rem!important;
// }

// h2 {
//   font-size: 1.8rem !important;
// }

// h3 {
//   font-size: 1.5rem !important;
// }

// h4 {
//   font-size: 1.1rem !important;
// }

// a {
//   font-size: 1.3rem;
// }

// div {
//   font-size: 1.2rem !important;
// }

// span {
//   font-size: 1.1rem !important;
// }

// p {
//   font-size: 1rem !important;
// }

// label {
//   font-size: 1.2rem !important;
// }

// li {
//   margin-top: 3px !important;
// }

.helplinks {
  font-size: 1rem !important;
}

