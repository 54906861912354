@import '../../styles/variables';

// :: ---

.tag {
  &:not(body):not(html) {
    margin-right: $gap / 4;
    background-color: rgba($grey-light, 0.54);
    color: $aws-squid-ink;

    &:hover {
      background-color: $link-hover;
      color: $white;
    }
  }
}
