@import '~bulma/sass/utilities/initial-variables.sass';

// :: TODO embed font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// :: custom brand colors
$aws-orange: #ff9900;
$aws-squid-ink: #232f3e;
$aws-squid-ink-dark: #161e2d;
$aws-white: #ffffff;

$aws-palette-orange: $aws-orange;
$aws-palette-orange-dark: #d45b07;
$aws-palette-orange-light: #ffcc83;

$aws-palette-pink: #ff4f8b;
$aws-palette-pink-dark: #b0084d;
$aws-palette-pink-light: #f79bc5;

$aws-palette-purple: #a166ff;
$aws-palette-purple-dark: #4d27aa;
$aws-palette-purple-light: #c5a1ff;

$aws-palette-red: #ff5252;

$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;

// :: update Bulma's global variables
//    https://bulma.io/documentation/customize/variables/
$family-sans-serif: 'Amazon Ember', 'Nunito', sans-serif;
$grey-darker: $aws-squid-ink;
$grey-dark: $brown;
$grey-light: $beige-light;
$black: $aws-squid-ink;
$white: $aws-white;
$primary: $aws-white;
$danger: $aws-palette-red;
$warning: $aws-palette-orange;
$text: $aws-squid-ink;

// $link: $aws-palette-purple;
$link-hover: $aws-palette-purple-dark;
// $link-focus: $aws-palette-purple-dark;
// $link-active: $aws-palette-purple-dark;
// $link-visited: $aws-palette-purple;

$control-border-width: 2px;
$input-background-color: $beige-lighter;
$input-border-color: transparent;
$input-shadow: none;

// :: masonry components
$card-width: 32%;
$gutter-width: 2%;
