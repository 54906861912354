@import '../../styles/variables.scss';

// :: ---

.formtag {
  margin-right: 8px;
  margin-bottom: 8px;

  cursor: pointer;

  background-color: $aws-palette-orange;
  border-color: $aws-palette-orange;
}

.guidelines {
  font-size: 0.9vw !important;
}

.guidelineslink {
  font-size: 1vw !important;
}
