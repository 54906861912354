@import '../../styles/variables.scss';

// :: ---

.projectcard {
  // width: $card-width;
  margin-bottom: 16px;
  height: 100%
  // overflow-wrap: break-word;
}

.projecttitle {
 display: -webkit-box;
 max-width: 100%;
 margin: 0 auto;
 -webkit-line-clamp: 1;
 /* autoprefixer: ignore-next */
 -webkit-box-orient: vertical;
 overflow: hidden;
 text-overflow: ellipsis;
}

.projectcardsubtitle {
  height: 80px;
  overflow: hidden;
}

.projectlaunch {
  float: right;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.projectcardheadertitle {
  height: 70px;
}

.projectcardimage{
  border-bottom-style: inset;
  border-bottom-color: #eee;
  border-width: 2px;
}

.projectcardtitle{
  word-break: break-word;
}

.scaleAspectRatio{
  display: inline-block;
  object-fit: contain;
}