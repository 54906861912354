@import '../../styles/variables.scss';

// :: ---

// :: used to dynamically determine the width of a
//    masonry column for percentage-based sized
.masonrywidthreference {
  width: $card-width;
}

.masonrygutterreference {
  width: $gutter-width;
}

// .selectNumber {
//   float: right;
//   display: flex;
//   margin-top: -40px;
// }

// .selectLabel {
//   padding-right: 5px;
// }

.pageNumberDiv {
  text-align: center;
}

.pageNumber {
  display: inline-flex !important;
}
