@import '../../styles/variables.scss';

// :: ---

.headericon {
  margin-left: 16px;
}

.navbar {
  border-bottom: 1px solid rgba($grey, 0.14);
  margin-bottom: $gap;
}

.homelink {
  color: white;
  font-size: 1.1rem;
}

.homelink:hover {
  color: #FF9900;
}

.logo {
  width: 13rem;
  margin-right: 7px;
}
