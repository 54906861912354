body {
  margin: 0;
  font-family: 
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-auth__box__container {
  min-height: 90vh; // height of the browser viewport
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

.custom-auth__box {
  padding-top: 25px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

@import './styles/variables.scss';
@import '~bulma/bulma';
